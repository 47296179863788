<template>
  <div>
    <GenericForm
      title="Fabricante"
      previousRoute="fabricante"
      editRoute="fabricanteEditar"
      :model="model"
      :route="fabricanteRoute"
      :editing="editing"
      @updateModel="updateModel"
      idModel="id_fabricante"
      :permissionsToWrite="['rw_vinculo_externo']"
      :permissionsToEdit="['re_vinculo_externo']"
      :permissionsToDelete="['rd_vinculo_externo']"
    >
      <template v-slot="{ validateState, isBusy }">
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Nome*" label-for="nome">
              <b-form-input
                id="nome"
                v-model="model.nome"
                autocomplete="off"
                class="invision-input"
                name="nome"
                :disabled="isBusy"
                v-validate="{ required: true }"
                :state="validateState('nome')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericForm>
  </div>
</template>

<script>
import GenericForm from '@/components/Form/GenericForm';

export default {
  components: {
    GenericForm,
  },

  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: {
        id_fabricante: this.$route.params.id || -1,
        nome: '',
      },

      fabricanteRoute: 'fabricante',
    };
  },

  methods: {
    updateModel(model) {
      this.model = model;
    },
  },
};
</script>
